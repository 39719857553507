import React, { useRef } from "react";
import useRouter from "../../hooks/useRouter";
import useEventListener from "../../hooks/useEventListener";
import { motion } from "framer-motion";

export default function Footer({ page }) {
  const { push } = useRouter();

  const allowScrollTransition = useRef(true);
  const handleScroll = (to) => {
    if (!allowScrollTransition.current) return;
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      delayRedirect(to);
      allowScrollTransition.current = false;
    }
  };

  const disableScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const delayRedirect = (to) => {
    disableScroll();
    allowScrollTransition.current = true;
    setTimeout(() => push(to), 0);
  };
  useEventListener("scroll", () => {
    handleScroll(`/${page.name}`);
  });
  return (
    <motion.div
      className="info-page-transition"
      onClick={() => push(`/${page.name}`)}
      exit={{
        y: "-75vh",
        height: "150vh",
        transition: { duration: 0.8, ease: [0.6, 0.01, -0.05, 0.95] },
      }}
    ></motion.div>
  );
}
