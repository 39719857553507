import { useEffect, useRef } from "react";
import {
  useGlobalDispatchContext,
  useGlobalStateContext,
} from "../context/globalContext";

export const useUpdateCursor = () => {
  const { cursorStyles } = useGlobalStateContext();
  const dispatch = useGlobalDispatchContext();
  return (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: "CURSOR_TYPE", cursorType: cursorType });
  };
};

export const useToggleLoading = () => {
  const dispatch = useGlobalDispatchContext();
  return () => {
    dispatch({ type: "SET_LOADING_STATE", loading: false });
  };
};

export const useShuffledProjects = () => {
  // Import projects from data
  const {
    data: { projects, portfolio },
  } = useGlobalStateContext();

  // Fisher-Yates (aka Knuth) Shuffle.
  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }

  // Shuffle projects
  const shuffledProjects = useRef(projects);
  useEffect(() => {
    shuffledProjects.current = shuffle(shuffledProjects.current);
  }, []);

  // Return shuffled array of projects
  return [...shuffledProjects.current, portfolio];
};
