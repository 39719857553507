import React from "react";
import { Link } from "react-router-dom";
import { useUpdateCursor } from "../../hooks/useContextSetters";

export default function CTA({ information }) {
  const onCursor = useUpdateCursor();
  return (
    <section className="about-call-to-action">
      <div className="container">
        <div className="content">
          <div className="text-container">
            <h2>{information.copy}</h2>
          </div>
          <Link to="/contact">
            <button
              onMouseEnter={() => onCursor("hovered")}
              onMouseLeave={onCursor}
            >
              {information.button}
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}
