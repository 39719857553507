import React, { useEffect, useRef, useMemo } from "react";
import useRouter from "../../hooks/useRouter";

import Design from "./Design";
import Branding from "./Branding";
import Responsive from "./Responsive";
import Footer from "./Footer";
import { useMouseMoveParallax, useMouseMoveScale } from "../../hooks/useMotion";
import { motion } from "framer-motion";
import AnimatedLetters from "../AnimatedLetters";
import { useGlobalStateContext } from "../../context/globalContext";
import { useUpdateCursor } from "../../hooks/useContextSetters";

export default function Project({ dimensions, mouse }) {
  const { pathname } = useRouter();
  const onCursor = useUpdateCursor();
  const {
    data: { projects },
  } = useGlobalStateContext();

  const main = useRef();

  const parallax = useMouseMoveParallax(mouse, main.current);
  const scale = useMouseMoveScale(mouse, main.current);

  const prevProject = useRef();
  const currentProject = useRef();
  const nextProject = useRef();
  const letters = useRef();

  useMemo(() => {
    currentProject.current = projects.find((e) => {
      return pathname.includes(e.name);
    });
    const index = projects.indexOf(currentProject.current);
    prevProject.current =
      projects[index <= 0 ? projects.length - 1 : index - 1];
    nextProject.current =
      projects[index >= projects.length - 1 ? 0 : index + 1];
    letters.current = currentProject.current.name.split("");
  }, [pathname, projects]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
    const enableScroll = () => {
      window.onscroll = () => {};
    };
    enableScroll();
  }, [pathname]);

  const {
    name,
    demo,
    text,
    sections: { design, branding, responsive },
    animation,
  } = currentProject.current;

  return (
    <div className="project-wrapper">
      <section className="project-landing" ref={main}>
        <img
          src={require(`../../assets/images/${name}/card/card3200.jpg`).default}
          alt={`${name} card`}
          style={{
            transform:
              dimensions.width > 800 ? `scale(${scale.scale.y})` : "scale(1)",
          }}
          loading="eager"
        />
        <motion.div
          initial={{ opacity: 0, visibility: "hidden" }}
          animate={{
            opacity: 1,
            visibility: "visible",
            transition: { delay: 1, duration: 0.3 },
          }}
          className="square-border project-square-border"
          style={{
            transform:
              dimensions.width > 800
                ? `translate3d(${-parallax.value.x * 0.005}%, ${
                    -parallax.value.y * 0.005 + 2
                  }%, 0)`
                : "translate3d(0, 0, 0)",
          }}
        >
          <div
            className="project-text-container"
            style={{
              transform:
                dimensions.width > 800
                  ? `translate3d(${parallax.value.x * 0.009}%, ${
                      parallax.value.y * 0.09
                    }%, 0)`
                  : "translate3d(0, 0, 0)",
            }}
          >
            <AnimatedLetters sentance={name} />
          </div>
          {demo && (
            <div
              className="project-text-container"
              style={{
                transform:
                  dimensions.width > 800
                    ? `translate3d(${parallax.value.x * 0.02}%, ${
                        parallax.value.y * 0.07
                      }%, 0)`
                    : "translate3d(0, 0, 0)",
              }}
            >
              <a
                href={`https://${name}.luis-villa.com`}
                target="_blank"
                rel="noreferrer"
                onMouseEnter={() => onCursor("hovered")}
                onMouseLeave={onCursor}
              >
                <AnimatedLetters sentance={"Demo"} />
              </a>
            </div>
          )}
        </motion.div>
      </section>
      <section className="project-overview">
        <div className="container">
          <div className="block-wrapper">
            <div className="row">
              <div className="text-block">
                <h2>Development</h2>
                {text.technologies.map((tech, index) => (
                  <p key={index}>{tech}</p>
                ))}
              </div>
              <div className="text-block">
                <h2>Date</h2>
                <p>{text.date}</p>
              </div>
              <div className="text-block">
                <h2>Design</h2>
                {text.programs.map((programs, index) => (
                  <p key={index}>{programs}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {design && <Design design={design} name={name} />}
      {branding && <Branding branding={branding} name={name} />}
      {responsive && (
        <Responsive responsive={responsive} name={name} animation={animation} />
      )}
      <Footer
        project={nextProject.current}
        dimensions={dimensions}
        scale={scale.scale.y}
      />
    </div>
  );
}
