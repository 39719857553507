import { useMemo, useEffect, useRef } from "react";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

export default function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const log = useRef([]);

  useEffect(() => {
    log.current.push(location.pathname);
  }, [location.pathname]);

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      listen: history.listen,
      pathname: location.pathname,
      match,
      location,
      history,
      log: log.current,
      params,
    };
  }, [params, match, location, history]);
}
