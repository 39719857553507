import React, { useState, useEffect, useRef } from "react";
import useRouter from "./hooks/useRouter";
import "./styles/App.scss";
import Header from "./components/Header";
import Home from "./components/home/Index";
import Project from "./components/project/Index";
import { Switch, Route } from "react-router-dom";
import Cursor from "./components/Cursor";
import useEventListener from "./hooks/useEventListener";
import useDebounceCallback from "./hooks/useDebounceCallback";
import { useMouseMove } from "./hooks/useMotion";
import Information from "./components/information/Index";
import Loader from "./components/Loader";
import { AnimatePresence } from "framer-motion";
import {
  useShuffledProjects,
  useUpdateCursor,
} from "./hooks/useContextSetters";
import LoadImages from "./components/LoadImages";
import { useGlobalStateContext } from "./context/globalContext";

function App() {
  const mouse = useMouseMove(0.35);
  const { pathname, location } = useRouter();
  const [dimensions, setDimensions] = useState({
    height: document.documentElement.clientHeight,
    width: document.documentElement.clientWidth,
  });

  // Handle app dimensions
  const handleResize = useDebounceCallback(() => {
    setDimensions({
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth,
    });
  }, 1000);
  useEventListener("resize", handleResize);
  useEffect(() => {
    let vh = dimensions.height * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  const {
    loading,
    // data: { projects },
  } = useGlobalStateContext();
  const projects = useShuffledProjects();
  const onCursor = useUpdateCursor();
  // shuffleTimer: added this delay because "moon" title was always shown first
  // might be able to fix by not passing projects as props (global context)
  const shuffleTimer = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      shuffleTimer.current = true;
    }, 100);
  }, []);

  return (
    <>
      {dimensions.width > 800 && !pathname.includes("/demo") && !loading && (
        <Cursor mouse={mouse} />
      )}
      {!pathname.includes("/demo") && !loading && (
        <Header dimensions={dimensions} />
      )}
      <div
        className="App"
        onMouseDown={() => onCursor("clicked")}
        onMouseUp={onCursor}
      >
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.key}>
            <Route path="/" exact>
              {loading && <Loader />}
              <LoadImages projects={projects} />

              {shuffleTimer.current && (
                <Home
                  dimensions={dimensions}
                  projects={projects}
                  mouse={mouse}
                  loading={loading}
                />
              )}
            </Route>
            <Route path="/:info" exact>
              <Information dimensions={dimensions} />
            </Route>
            <Route path="/projects/:name" exact>
              <Project
                dimensions={dimensions}
                projects={projects}
                mouse={mouse}
              />
            </Route>
          </Switch>
        </AnimatePresence>
      </div>
    </>
  );
}

export default App;
