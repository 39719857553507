import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import useEventListener from "./useEventListener";

export const useMouseMove = (
  speedX = 0.25,
  speedY = speedX,
  target = window
) => {
  const targetWidth =
    target.innerWidth ||
    target.clientWidth ||
    target.getBoundingClientRect().width;
  const targetHeight =
    target.innerHeight ||
    target.clientHeight ||
    target.getBoundingClientRect().height;
  const [mouse, setMouse] = useState({
    mouseX: targetWidth / 2,
    mouseY: targetHeight / 2,
  });
  const [animated, setAnimated] = useState({
    animatedX: targetWidth / 2,
    animatedY: targetHeight / 2,
  });

  const animationFrame = useRef(0);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setMouse({
      mouseX: clientX,
      mouseY: clientY,
    });
  };

  const moveCursor = useCallback(() => {
    const { mouseX, mouseY } = mouse;
    const { animatedX, animatedY } = animated;
    const diffX = mouseX - animatedX;
    const diffY = mouseY - animatedY;
    setAnimated(
      (currAnimated) =>
        (currAnimated = {
          animatedX: animatedX + diffX * speedX * window.devicePixelRatio,
          animatedY: animatedY + diffY * speedY * window.devicePixelRatio,
        })
    );
  }, [mouse, animated, speedX, speedY]);

  useEventListener("mousemove", handleMouseMove, target);

  useEffect(() => {
    animationFrame.current = requestAnimationFrame(moveCursor);
    return () => {
      cancelAnimationFrame(animationFrame.current);
    };
  }, [moveCursor]);

  const { mouseX, mouseY } = mouse;
  const { animatedX, animatedY } = animated;

  return useMemo(() => {
    return {
      mouse: { x: mouseX, y: mouseY },
      animated: { x: animatedX, y: animatedY },
    };
  }, [mouseX, mouseY, animatedX, animatedY]);
};

export const useMouseMoveParallax = (mouse, target = window) => {
  const targetWidth =
    target.innerWidth ||
    target.clientWidth ||
    target.getBoundingClientRect().width;
  const targetHeight =
    target.innerHeight ||
    target.clientHeight ||
    target.getBoundingClientRect().height;

  const [displacement, setDisplacement] = useState({
    displacementX: 0,
    displacementY: 0,
  });
  const getValue = useCallback(() => {
    const x = mouse.animated.x - targetWidth / 2;
    const y = mouse.animated.y - targetHeight / 2;

    setDisplacement({
      displacementX: x,
      displacementY: y,
    });
  }, [mouse, targetHeight, targetWidth]);

  useEffect(() => {
    getValue();
  }, [getValue]);

  const { displacementX, displacementY } = displacement;

  return useMemo(() => {
    return {
      value: { x: displacementX, y: displacementY },
    };
  }, [displacementX, displacementY]);
};

export const useMouseMoveScale = (mouse, target = window) => {
  const targetWidth =
    target.innerWidth ||
    target.clientWidth ||
    target.getBoundingClientRect().width;
  const targetHeight =
    target.innerHeight ||
    target.clientHeight ||
    target.getBoundingClientRect().height;
  const [scale, setScale] = useState({ x: 1, y: 1 });
  const getScale = useCallback(() => {
    const x = (targetWidth - mouse.animated.x) / (targetWidth * 10) + 1;
    const y = (targetHeight - mouse.animated.y) / (targetHeight * 10) + 1;

    setScale({
      x: x,
      y: y,
    });
  }, [mouse, targetWidth, targetHeight]);

  useEffect(() => {
    getScale();
  }, [getScale]);

  const { x, y } = scale;

  return useMemo(() => {
    return {
      scale: { x: x, y: y },
    };
  }, [x, y]);
};
