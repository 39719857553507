import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { AnimatePresence, motion, useAnimation } from "framer-motion";

export default function AnimateWhenVisible({
  children,
  variants = {
    initial: { opacity: 0, x: 1000 },
    animate: { opacity: 1, x: 0 },
  },
  transition = null,
  options = { triggerOnce: true },
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView(options);

  useEffect(() => {
    if (inView) {
      controls.start("animate");
    } else {
      controls.start("initial");
    }
  }, [controls, inView]);

  return (
    <AnimatePresence>
      <motion.div
        ref={ref}
        variants={variants}
        initial="initial"
        animate={controls}
        exit={controls}
        transition={transition}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
