import React from "react";
import { useGlobalStateContext } from "../../context/globalContext";
import AnimateWhenVisible from "../AnimateWhenVisible";
import StaggeredImages from "../StaggeredImages";

export default function Design({ name, responsive: { text, images } }) {
  const {
    data: { animation },
  } = useGlobalStateContext();
  return (
    <section className="responsive" style={{ position: "relative", zIndex: 0 }}>
      <section className="project-copy">
        <div className="container">
          <div className="text-container">
            <h2>{text.heading}</h2>
            <p>{text.copy}</p>
          </div>
        </div>
      </section>
      <section className="project-responsive">
        <div className="container">
          <AnimateWhenVisible
            variants={animation.variants}
            options={animation.options}
          >
            <picture>
              <img
                src={
                  require(`../../assets/images/${name}/responsive/desktop.jpg`)
                    .default
                }
                alt={images.browsers.alt}
                loading="lazy"
              ></img>
            </picture>
          </AnimateWhenVisible>
          <StaggeredImages images={images.test} />
        </div>
      </section>
      <section className="project-images">
        <div className="container">
          <AnimateWhenVisible
            variants={animation.variants}
            options={animation.options}
          >
            {images.pages.map((page, index) => {
              return (
                <picture key={index}>
                  <img
                    key={index}
                    src={
                      require(`../../assets/images/${page.image}.jpg`).default
                    }
                    alt={page.alt}
                    loading="lazy"
                  ></img>
                </picture>
              );
            })}
          </AnimateWhenVisible>
        </div>
      </section>
    </section>
  );
}
