import React, { useEffect, useRef, useMemo } from "react";
import useRouter from "../../hooks/useRouter";
import Creativity from "./Creativity";
import Location from "./Location";
import HorizontalTextScroll from "./HorizontalTextScroll";
import FAQ from "./FAQ";
import Footer from "./Footer";
import CTA from "./CTA";
import Steps from "./Steps";
// import Contact from "./Contact";
import { ReactComponent as Arrow } from "../../assets/vectors/arrow.svg";
import { motion } from "framer-motion";
import AnimatedLetters from "../AnimatedLetters";
import { useGlobalStateContext } from "../../context/globalContext";

export default function Information({ dimensions }) {
  const { pathname } = useRouter();
  const {
    data: { information },
  } = useGlobalStateContext();

  const overlayVariant = {
    initial: {
      opacity: 1,
    },
    animate: {
      opacity: 0,
      transition: { duration: 0.1, delay: 0.15 },
    },
  };

  const prevPage = useRef();
  const currentPage = useRef();
  const nextPage = useRef();

  useMemo(() => {
    currentPage.current = information.find((e) => {
      return pathname.includes(e.name);
    });
    const index = information.indexOf(currentPage.current);
    prevPage.current =
      information[index <= 0 ? information.length - 1 : index - 1];
    nextPage.current =
      information[index >= information.length - 1 ? 0 : index + 1];
  }, [pathname, information]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTo(0, 0);
    const enableScroll = () => {
      window.onscroll = () => {};
    };
    enableScroll();
  }, [pathname]);

  const {
    main: { description, line },
    creativity,
    location,
    services,
    questions,
    steps,
    // inputs,
    // message,
    CTA: callToAction,
  } = currentPage.current;

  return (
    <div className="main-dark-background">
      <section className="about-landing">
        <motion.div
          className="info-overlay"
          variant={overlayVariant}
          initial={{ opacity: 1 }}
          animate={{
            opacity: 0,
            transition: { duration: 0.3, delay: 0.2 },
          }}
        ></motion.div>
        <div className="content">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.1, delay: 0.7 } }}
          >
            {description}
          </motion.p>
          <AnimatedLetters sentance={line} stagger={0.05} duration={0.7} />
          <div className="scroll-indicator">
            <Arrow />
          </div>
        </div>
      </section>
      {pathname.includes("about") && (
        <div>
          <Creativity dimensions={dimensions} information={creativity} />
          <Location information={location} />
        </div>
      )}
      {pathname.includes("services") && (
        <div>
          <HorizontalTextScroll
            information={services}
            dimensions={dimensions}
          />
          <FAQ information={questions} />
        </div>
      )}
      {pathname.includes("approach") && (
        <div>
          <Steps steps={steps} width={dimensions.width} />
        </div>
      )}
      {/* {pathname.includes("contact") && (
        <div>
          <Contact inputs={inputs} message={message} />
        </div>
      )} */}
      {!pathname.includes("contact") && <CTA information={callToAction} />}
      <Footer page={nextPage.current} />
    </div>
  );
}
