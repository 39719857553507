import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import useRouter from "../../hooks/useRouter";
import useEventListener from "../../hooks/useEventListener";
import { motion, useTransform, useViewportScroll } from "framer-motion";

export default function Footer({ project, dimensions, scale }) {
  const { push, pathname } = useRouter();
  const { scrollYProgress } = useViewportScroll();
  const scrollScale = useTransform(scrollYProgress, [0, 0.8, 1], [0, 0, 1]);
  const scrollOpacity = useTransform(scrollYProgress, [0, 0.9, 1], [0, 0, 1]);

  const pageTransition = {
    exit: {
      height: "100vh",
      transition: { duration: 0.8, ease: [0.6, 0.01, -0.05, 0.95] },
    },
    exitImage: {
      height: "100vh",
      y: "-50vh",
      transition: { duration: 0.8, ease: [0.6, 0.01, -0.05, 0.95] },
    },
    text: { opacity: 0, y: "20vh", transition: { duration: 0.3 } },
  };

  const allowScrollTransition = useRef(true);

  const handleScroll = (to) => {
    if (!allowScrollTransition.current) return;
    if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
      delayRedirectScroll(to);
      allowScrollTransition.current = false;
    }
  };
  useEffect(() => {
    setTimeout(() => {
      allowScrollTransition.current = true;
    }, 300);
  }, [pathname]);

  useEventListener("scroll", () => {
    handleScroll(`/projects/${project.name}`);
  });

  const disableScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };

  const delayRedirect = (e, to) => {
    e.preventDefault();
    disableScroll();
    setTimeout(() => push(to), 0);
  };
  const delayRedirectScroll = (to) => {
    disableScroll();
    setTimeout(() => push(to), 0);
  };

  const { name } = project;

  return (
    <motion.footer variants={pageTransition} exit="exit">
      <Link to="/" onClick={(e) => delayRedirect(e, `/projects/${name}`)}>
        <motion.div
          className="footer-content"
          variants={pageTransition}
          exit="exitImage"
        >
          <motion.div
            className="footer-text"
            style={{ opacity: scrollOpacity }}
            variants={pageTransition}
            exit="text"
          >
            <h2>Next Project</h2>
          </motion.div>
          <motion.div
            className="footer-image"
            style={{
              scale: scrollScale,
            }}
          >
            <img
              src={
                require(`../../assets/images/${name}/card/card3200.jpg`).default
              }
              alt={`${name} card`}
              style={{
                transform:
                  dimensions.width > 800 ? `scale(${scale})` : "scale(1)",
              }}
              loading="eager"
            />
          </motion.div>
        </motion.div>
      </Link>
    </motion.footer>
  );
}
