import { ReactComponent as Koi } from "../assets/vectors/koi.svg";
import { ReactComponent as Wolf } from "../assets/vectors/wolf.svg";
import { ReactComponent as Owl } from "../assets/vectors/owl.svg";
import { ReactComponent as California } from "../assets/vectors/california.svg";
import { ReactComponent as Alabama } from "../assets/vectors/alabama.svg";
import { ReactComponent as PersonalLogo } from "../assets/images/portfolio/logo.svg";
import { ReactComponent as MoonLogo } from "../assets/images/moon/logo.svg";
import { ReactComponent as TravelLogo } from "../assets/images/travel/logo.svg";
import { ReactComponent as FurrowLogo } from "../assets/images/furrow/logo.svg";

// Artwork images
import wolf from "../assets/images/art/wolf-min.jpg";
import fish from "../assets/images/art/fish-min.jpg";
import compass from "../assets/images/art/compass-min.jpg";
import rhino from "../assets/images/art/rhino-min.jpg";
import lion from "../assets/images/art/lion-min.jpg";

// Images for travel responsive section
import travelHomeS from "../assets/images/travel/responsive/phone-home.jpg";
import travelPageS from "../assets/images/travel/responsive/phone-page.jpg";
import travelPageCatchyS from "../assets/images/travel/responsive/phone-page-catchy.jpg";
import travelPageInfoS from "../assets/images/travel/responsive/phone-page-info.jpg";
import travelHomeM from "../assets/images/travel/responsive/tablet-home.jpg";
import travelPageM from "../assets/images/travel/responsive/tablet-page.jpg";
import travelPageFooterM from "../assets/images/travel/responsive/tablet-page-footer.jpg";
import travelPageTopM from "../assets/images/travel/responsive/tablet-page-top.jpg";

// Images for moon responsive section
import moonLuminS from "../assets/images/moon/responsive/phone-lumin.jpg";
import moonLuminInfoS from "../assets/images/moon/responsive/phone-lumin-info.jpg";
import moonCurologyM from "../assets/images/moon/responsive/tablet-curology.jpg";
import moonCurologyFooterM from "../assets/images/moon/responsive/tablet-curology-footer.jpg";
import moonLuminM from "../assets/images/moon/responsive/tablet-lumin.jpg";
import moonLuminInfoM from "../assets/images/moon/responsive/tablet-lumin-info.jpg";

// Images for furrow responsive section
import furrowHomeDarkS from "../assets/images/furrow/responsive/phone.jpg";
import furrowHomeLightS from "../assets/images/furrow/responsive/phone-light.jpg";
import furrowAboutDarkS from "../assets/images/furrow/responsive/phone-about-dark.jpg";
import furrowFeaturedLightS from "../assets/images/furrow/responsive/phone-featured-light.jpg";
import furrowHomeDarkM from "../assets/images/furrow/responsive/tablet.jpg";
import furrowHomeLightM from "../assets/images/furrow/responsive/tablet-light.jpg";
import furrowAboutLightM from "../assets/images/furrow/responsive/tablet-about-light.jpg";
import furrowFeaturedDarkM from "../assets/images/furrow/responsive/tablet-featured-dark.jpg";

// Images for portfolio responsive section
import portfolioTravelS from "../assets/images/portfolio/responsive/travel-S.jpg";
import portfolioMoonS from "../assets/images/portfolio/responsive/moon-S.jpg";
import portfolioFurrowS from "../assets/images/portfolio/responsive/furrow-S.jpg";
import portfolioAboutS from "../assets/images/portfolio/responsive/about-S.jpg";
import portfolioApproachS from "../assets/images/portfolio/responsive/approach-S.jpg";

export const data = {
  information: [
    {
      name: "about",
      main: {
        description: "Front-end Developer / Designer",
        line: "Driven by the fact that what I create today will be better than what I created yesterday.",
      },
      creativity: {
        title: "Passion for creativity",
        copy: "I've had a passion for art since I was a child. I have been drawing since before I can remember. Recently, stippling with a pen has become my preferred style. Some of my favorite works can be seen below.",
        images: [wolf, fish, compass, rhino, lion],
      },
      location: {
        title: "Born and Raised",
        copy: "I was born on the 26th of February in Northern Alabama. At the age of 13, my family moved to Southern California, where I've spent the last half of my life.",
      },
      CTA: {
        copy: "Have an idea? Tell me about it!",
        button: "Get in Touch",
      },
    },
    {
      name: "approach",
      main: {
        description: "Approach",
        line: "Driven by the fact that what I create today will be better than what I created yesterday.",
      },
      steps: {
        steps: [
          {
            title: "Discovery",
            copy: "During this step we will discuss everything your project entails. I'll ask a few simple questions to get an idea of the project's scope and answer any concerns you may have regarding the project. This initial step will set a solid foundation for the entire project.",
          },
          {
            title: "Planning",
            copy: "After we've discussed the needs of your particular project, I'll begin planning out the structure of the website. This will produce a diagram detailing the flow of the website. It will include the total number of pages required for your project, the relationship between pages, as well as a general overview of each page's purpose.",
          },
          {
            title: "Concept",
            copy: "Along with the structure of the website, I'll generate a multiple sketches of the main pages of your site. I'll then turn those sketches into low-fidelity digital mockups using a wide array of modern design software. This step is crucial for displaying your data in the most efficient and effective manner.",
          },
          {
            title: "Design",
            copy: "This stage of the project is where we begin to add some polish. We'll substitute the final images into the rough mockups to create the high-fidelity mockup. All product photography and content creation will be completed before the end of this step. Smoother development.",
          },
          {
            title: "Development",
            copy: "This is the point where all the work we've put in begins to come alive. I'll turn the high fidelity mockups into code, which will allow you to interact with the website, and eventually allow your clients to interact with you. Through a demo website, you'll also be able to see the progress made with each update. You'll be there every step of the way.",
          },
          {
            title: "Strategy",
            copy: "A manual for your brand. At the end of the project I will create a comprehensive guide for your brand's identity. This will include guidelines for your social media content as well as any content you wish to add to your new website. This final step will help provide direction for your brand.",
          },
        ],
      },
      CTA: {
        copy: "Got a project? Let's team up!",
        button: "Let's Chat",
      },
    },
    {
      name: "services",
      main: {
        description: "Services",
        line: "A self-starter excited to help create memorable experiences through minimalistic design.",
      },
      services: {
        title: "Passion for creativity",
        copy: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam ",
        services: [
          "Design",
          "Branding",
          "Digital",
          "Illustration",
          "3D",
          "Photography",
          "Photo Manipulation",
          "Development",
        ],
      },
      questions: {
        title: "Passion for creativity",
        copy: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quam ",
        questions: [
          {
            title: "How much do your services cost?",
            answers: [
              "The short answer is that my previous work has ranged between $2000 and $10,000.",
              "The long answer is that the final price is directly related to the project scope, timeline, and deliverables. As the project scope can’t be determined without a formed strategy, the first stage of the project is discovery.",
              "After this stage, we’ll have a clear overview of what we are building, what resources are required, and how much time we’ll need to complete the project. Once we have a clear picture, I’ll be able to propose a final price and deadline.",
            ],
          },
          {
            title: "How long will a web development project take?",
            answers: [
              "It depends on the project scope. From previous experience, designing and developing a new landing page can take up to 2 weeks, while larger websites (6-12 pages), can take up to 4-6 weeks.",
            ],
          },
          {
            title: "What can I expect on our first call?",
            answers: [
              "The first step of starting our cooperation is a 30-minute discovery call. In this call we’ll try to define your goals, perform problem discovery and identify a potential solution to your problem.",
              "After our call I’ll send you a proposal for a discovery session, so you can approve and set a down payment. Once we complete the the discovery phase, I’ll be able to compose and deliver a final project proposal, and then we can officially start our project.",
            ],
          },
        ],
      },
      CTA: {
        copy: "Have a cool idea in mind? Let’s collaborate and turn it into reality.",
        button: "Let's Talk",
      },
    },
    // {
    //   name: "contact",
    //   main: {
    //     description: "Contact",
    //     line: "I'd love to hear about your projects and find a custom solution for you.",
    //   },
    //   message: {
    //     success: {
    //       line: "Thank you! I'll reach out soon.",
    //       button: "Send Another",
    //     },
    //     fail: {
    //       line: "Sorry! Message failed to send.",
    //       button: "Try Again",
    //     },
    //   },
    //   inputs: [
    //     {
    //       label: "Hey, my name is ",
    //       placeholder: "your name here",
    //       name: "name",
    //       type: "text",
    //     },
    //     {
    //       label: "and I'm looking for ",
    //       placeholder: "project description",
    //       name: "description",
    //       type: "text",
    //     },
    //     {
    //       label: "My budget is ",
    //       placeholder: "your budget here",
    //       name: "budget",
    //       type: "text",
    //     },
    //     {
    //       label: "and the timeframe is ",
    //       placeholder: "expected deadline date",
    //       name: "deadline",
    //       type: "text",
    //     },
    //     {
    //       label: "You can reach me at ",
    //       placeholder: "your email here",
    //       name: "email",
    //       type: "email",
    //     },
    //   ],
    //   CTA: {
    //     copy: "Have an idea? Tell me about it!",
    //     button: "Get in Touch",
    //   },
    // },
  ],
  projects: [
    {
      name: "moon",
      demo: true,
      text: {
        technologies: ["ReactJS", "SCSS", "HTML5", "Github"],
        date: "September 2020",
        programs: ["Adobe XD", "Adobe PhotoShop", "Adobe Illustrator"],
      },
      sections: {
        design: {
          text: {
            heading: "Lively transitions",
            copy: "Moon is the name of a fictitious web development agency. This project's goal was to develop a website that showcased elegant transitions between the various pages, achieved with the help of the GreenSock Animation Platform.",
          },
          images: {
            logo: <MoonLogo />,
          },
          fonts: [
            {
              name: "Josefin Sans",
              style: "'Josefin Sans', sans-serif",
            },
          ],
          colors: [
            {
              pantone: "Solid Coated 7499 C",
              cmyk: {
                c: "0%",
                m: "9%",
                y: "28%",
                k: "0%",
              },
              rgb: {
                r: "255",
                g: "233",
                b: "183",
              },
              hex: "#ffe9b7",
            },
            {
              pantone: "Black 6 C",
              cmyk: {
                c: "0%",
                m: "0%",
                y: "0%",
                k: "100%",
              },
              rgb: {
                r: "0",
                g: "0",
                b: "0",
              },
              hex: "#000000",
            },
          ],
        },
        branding: {
          text: {
            heading: "Modern Branding",
            copy: "The main challenge with this project appeared when trying to create a brand that displayed a touch of elegance and formality while retaining an overall modern core. This was achieved through the use of curves for the logo as well as for the typography. Using a subdued friendly yellow to further drive the point.",
          },
          images: {
            squares: [
              {
                image: "moon/card-front-square",
                alt: "business card front mockup",
              },
              {
                image: "moon/card-back-square",
                alt: "business card back mockup",
              },
            ],
            mockups: [
              {
                image: "moon/card-envelope",
                alt: "envelope and business card mockup",
              },
            ],
          },
        },
        responsive: {
          text: {
            heading: "Minimalistic Layout",
            copy: "Following a smooth transition, the home page presents the case studies in a row along its bottom edge, switching to a column layout when viewed on mobile. Each specific case study page consists of a vertically aligned layout that boasts large, bold typography and fullscreen images. Followed by a page transition that makes viewing all case studies a seamless process.",
          },
          images: {
            browsers: [
              {
                image: "moon/responsive/desktop-browser",
                alt: "desktop browser screenshot",
              },
              {
                image: "moon/responsive/phone-browser",
                alt: "phone browser screenshot",
              },
            ],
            test: [
              moonLuminS,
              moonCurologyM,
              moonLuminInfoS,
              moonCurologyFooterM,
              moonLuminM,
              moonLuminInfoM,
            ],
            pages: [
              {
                image: "moon/navigation",
                alt: "moon navigation screenshot",
              },
              {
                image: "moon/page",
                alt: "moon page screenshot",
              },
            ],
          },
        },
      },
    },
    {
      name: "travel",
      demo: true,
      text: {
        technologies: ["ReactJS", "CSS3", "HTML5", "Github"],
        date: "August 2020",
        programs: ["Adobe XD", "Adobe PhotoShop", "Adobe Illustrator"],
      },
      sections: {
        design: {
          text: {
            heading: "Light and Dark",
            copy: "Travel is the fictitious name of a modern travel agency. This project was inspired by memories of a pre-pandemic society, with hopeful aspirations of a quick return to the norm. In the meantime, its design-process can help imaginations explore new ideas.",
          },
          images: {
            logo: <TravelLogo />,
          },
          fonts: [
            {
              name: "Vollkorn",
              style: "'Vollkorn', serif",
            },
            {
              name: "Open Sans",
              style: "'Open Sans', sans-serif",
            },
          ],
          colors: [
            {
              pantone: "Black 6 C",
              cmyk: {
                c: "0%",
                m: "0%",
                y: "0%",
                k: "100%",
              },
              rgb: {
                r: "0",
                g: "0",
                b: "0",
              },
              hex: "#000000",
            },
            {
              pantone: "Cool Gray 4 C",
              cmyk: {
                c: "0%",
                m: "0%",
                y: "0%",
                k: "25%",
              },
              rgb: {
                r: "190",
                g: "190",
                b: "190",
              },
              hex: "#bebebe",
            },
          ],
        },
        branding: {
          text: {
            heading: "Black and White",
            copy: "An identity centered around a timeless duo, black and white. Combining the cleanliness of light and the mysteriousness of darkness. Merging together to achieve a simple, yet formal look. The serif font, sharp edges, and grayscale imagery working together to drive the point home.",
          },
          images: {
            squares: [
              {
                image: "travel/card-front-square",
                alt: "business card front mockup",
              },
              {
                image: "travel/card-back-square",
                alt: "business card back mockup",
              },
            ],
            mockups: [
              {
                image: "travel/card-envelope",
                alt: "envelope and business card mockup",
              },
            ],
          },
        },
        responsive: {
          text: {
            heading: "Fog Inspired",
            copy: "The fullscreen home page brings attention to a travel advisor and flows fluidly to show a few possible travel destinations. Pages discussing the travel destinations transition to a white on black design.",
          },
          images: {
            browsers: [
              {
                image: "travel/responsive/desktop-browser",
                alt: "desktop browser screenshot",
              },
              {
                image: "travel/responsive/phone-browser",
                alt: "phone browser screenshot",
              },
            ],
            test: [
              travelHomeS,
              travelPageS,
              travelPageCatchyS,
              travelPageInfoS,
              travelHomeM,
              travelPageM,
              travelPageFooterM,
              travelPageTopM,
            ],
            pages: [
              {
                image: "travel/navigation",
                alt: "travel navigation screenshot",
              },
              {
                image: "travel/page",
                alt: "travel page screenshot",
              },
            ],
          },
        },
      },
    },
    {
      name: "furrow",
      demo: true,
      text: {
        technologies: ["NextJS", "Typescript", "SCSS", "Github"],
        date: "August 2021",
        programs: ["Framer", "Affinity Photo", "Affinity Designer"],
      },
      sections: {
        design: {
          text: {
            heading: "A form of flattery",
            copy: "Furrow is a studio that focuses on video production and post production. This project is a clone of their current homepage, with some improved performance, in order to sharpen my web development skills. During this project I learned how to implement NextJS in combination with typescript's static typing.",
          },
          images: {
            logo: <FurrowLogo />,
          },
          fonts: [
            {
              name: "Montserrat",
              style: "'Montserrat', sans-serif",
            },
            {
              name: "Open Sans",
              style: "'Open Sans', sans-serif",
            },
          ],
          colors: [
            {
              pantone: "Bright Red C",
              cmyk: {
                c: "0%",
                m: "83%",
                y: "87%",
                k: "8%",
              },
              rgb: {
                r: "234",
                g: "40",
                b: "30",
              },
              hex: "#ea281e",
            },
            {
              pantone: "Black 6 C",
              cmyk: {
                c: "0%",
                m: "0%",
                y: "0%",
                k: "100%",
              },
              rgb: {
                r: "0",
                g: "0",
                b: "0",
              },
              hex: "#000000",
            },
          ],
        },
        responsive: {
          text: {
            heading: "Responsive duality",
            copy: "A website designed with both mobile and desktop views in minds. Enjoy snappy load times with minimal animation for smaller mobile screens and impressive interactions on larger desktop screens. Erase away the canvas to reveal the content in the site's landing section or toggle the website's theme between light and dark mode to match your current mood.",
          },
          images: {
            browsers: [
              {
                image: "furrow/responsive/desktop",
                alt: "desktop browser screenshot",
              },
              {
                image: "furrow/responsive/phone-browser",
                alt: "phone browser screenshot",
              },
            ],
            test: [
              furrowHomeDarkS,
              furrowHomeLightS,
              furrowAboutDarkS,
              furrowFeaturedLightS,
              furrowHomeDarkM,
              furrowHomeLightM,
              furrowAboutLightM,
              furrowFeaturedDarkM,
            ],
            pages: [
              {
                image: "furrow/navigation",
                alt: "furrow navigation screenshot",
              },
              {
                image: "furrow/page",
                alt: "furrow page screenshot",
              },
            ],
          },
        },
      },
    },
  ],
  portfolio: {
    name: "portfolio",
    text: {
      technologies: ["ReactJS", "SCSS", "Framer Motion", "Git"],
      date: "October 2020",
      programs: [
        "Framer",
        "Affinity Photo",
        "Affinity Designer",
        "Affinity Publisher",
      ],
    },
    sections: {
      design: {
        text: {
          heading: "Less is more",
          copy: "A subtle beauty and refreshing clarity can be found when admiring the simplicity of minimalistic works. The perfect blend between efficiency and creativity. Finding new ways to implement minimalism without compromising functionality is a continuous challenge, albeit an enjoyable one. It is a challenge this portfolio confronts head on.",
        },
        images: {
          logo: <PersonalLogo />,
        },
        fonts: [
          {
            name: "Lato",
            style: "'Lato', sans-serif",
          },
          {
            name: "Victor Mono",
            style: "'Victor Mono', sans-serif",
          },
        ],
        colors: [
          {
            pantone: "Solid Coated 295 C",
            cmyk: {
              c: "100%",
              m: "49%",
              y: "0%",
              k: "68%",
            },
            rgb: {
              r: "10",
              g: "30",
              b: "65",
            },
            hex: "#0A1E41",
          },
          {
            pantone: "Solid Coated 305 C",
            cmyk: {
              c: "61%",
              m: "22%",
              y: "0%",
              k: "0%",
            },
            rgb: {
              r: "100",
              g: "200",
              b: "255",
            },
            hex: "#64C8FF",
          },
        ],
      },
      branding: {
        text: {
          heading: "A refined brand",
          copy: "A simple square logo representing the initials L and V is the cornerstone of the brand. The straight edges and sharp corners add a formal, yet sleek tone. With the choice of a dark royal blue as the primary brand color reinforcing the point; the accompanying sky blue accent representing an inviting and friendly demeanor.",
        },
        images: {
          squares: [
            {
              image: "portfolio/card-front-square",
              alt: "business card front mockup",
            },
            {
              image: "portfolio/card-back-square",
              alt: "business card back mockup",
            },
          ],
          mockups: [
            {
              image: "portfolio/card-envelope",
              alt: "envelope and business card mockup",
            },
          ],
        },
      },
      responsive: {
        text: {
          heading: "Straightforward Layout",
          copy: "The final product displays the essentials with little to no need for additional elements or information. The UX is made up of a minimal set of interactions, accompanied by smooth animations and transitions, that deliver easily consumable static content.",
        },
        images: {
          browsers: [
            {
              image: "portfolio/responsive/desktop-browser",
              alt: "desktop screenshot",
            },
            {
              image: "portfolio/responsive/tablet-browser",
              alt: "tablet screenshot",
            },
          ],
          test: [
            portfolioTravelS,
            portfolioMoonS,
            portfolioFurrowS,
            portfolioAboutS,
            portfolioApproachS,
          ],
          pages: [
            {
              image: "portfolio/navigation",
              alt: "portfolio navigation screenshot",
            },
            {
              image: "portfolio/page",
              alt: "portfolio page screenshot",
            },
          ],
        },
      },
    },
  },
  icons: [
    <Koi className="object" />,
    <California className="object" />,
    <Wolf className="object" />,
    <Owl className="object" />,
    <Alabama className="object" />,
  ],
  animation: {
    variants: {
      initial: {
        opacity: 0,
        y: 150,
      },
      animate: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 0.8,
          ease: [0.6, 0.05, -0.01, 0.9],
        },
      },
    },
    options: {
      triggerOnce: true,
      rootMargin: "-100px",
    },
  },
};
