import React from "react";

export default function Design({ design: { text, images, fonts, colors } }) {
  return (
    <section className="design">
      <section className="project-copy">
        <div className="container">
          <div className="text-container">
            <h2>{text.heading}</h2>
            <p>{text.copy}</p>
          </div>
        </div>
      </section>
      <div className="container">
        <div
          className="block logo"
          style={{ background: colors[0].hex, fill: "white", stroke: "white" }}
        >
          {images.logo}
        </div>
        <div
          className="block logo white"
          style={{ fill: colors[0].hex, stroke: colors[0].hex }}
        >
          {images.logo}
        </div>
        {colors.map((color, index) => (
          <div
            key={index}
            className="block color"
            style={{ background: color.hex }}
          >
            <div className="color-space">
              <h3>Pantone</h3>
              <p>{color.pantone}</p>
            </div>
            <div className="color-space">
              <div className="color-channel">
                <h3>C</h3>
                <p>{color.cmyk.c}</p>
              </div>
              <div className="color-channel">
                <h3>M</h3>
                <p>{color.cmyk.m}</p>
              </div>
              <div className="color-channel">
                <h3>Y</h3>
                <p>{color.cmyk.y}</p>
              </div>
              <div className="color-channel">
                <h3>K</h3>
                <p>{color.cmyk.k}</p>
              </div>
            </div>
            <div className="color-space">
              <div className="color-channel">
                <h3>R</h3>
                <p>{color.rgb.r}</p>
              </div>
              <div className="color-channel">
                <h3>G</h3>
                <p>{color.rgb.g}</p>
              </div>
              <div className="color-channel">
                <h3>B</h3>
                <p>{color.rgb.b}</p>
              </div>
            </div>
            <div className="color-space">
              <h3>Hex</h3>
              <p>{color.hex}</p>
            </div>
            <div className="color-swatches">
              <div className="color-swatch" style={{ background: color.hex }} />
              <div className="color-swatch" style={{ background: color.hex }} />
              <div className="color-swatch" style={{ background: color.hex }} />
              <div className="color-swatch" style={{ background: color.hex }} />
              <div className="color-swatch" style={{ background: color.hex }} />
            </div>
          </div>
        ))}
        {fonts.map((font, index) => (
          <div
            key={index}
            className={`block font ${index === 0 && "white"}`}
            style={{
              background:
                index !== 0 &&
                colors[colors.length > 2 ? colors.length - index : 0].hex,
            }}
          >
            <h2 style={{ fontFamily: font.style }}>{font.name}</h2>
            <h3 style={{ fontFamily: font.style }}>Aa Bb Cc 123</h3>
            <p style={{ fontFamily: font.style }}>ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>
            <p style={{ fontFamily: font.style }}>abcdefghijklmnopqrstuvwxyz</p>
            <p style={{ fontFamily: font.style }}>1234567890!@#$%^&*()_+"'</p>
          </div>
        ))}
      </div>
    </section>
  );
}
