import React, { useRef } from "react";
import { CardV2 } from "./CardV2";
import IntroOverlay from "./IntroOverlay";
import { useMouseMoveParallax, useMouseMoveScale } from "../../hooks/useMotion";

export default function Home({ projects, dimensions, mouse, loading }) {
  const main = useRef();
  const parallax = useMouseMoveParallax(mouse, main.current);
  const scale = useMouseMoveScale(mouse, main.current);

  return (
    <div className="main">
      <IntroOverlay loading={loading} />
      <div className="example-container" ref={main}>
        <CardV2
          projects={projects}
          dimensions={dimensions}
          parallax={parallax}
          scale={scale.scale.y}
        />
      </div>
    </div>
  );
}
