import React from "react";
import AnimateWhenVisible from "./AnimateWhenVisible";

const StaggeredImages = ({ images }) => {
  const variants = {
    initial: {
      opacity: 0,
      y: 150,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.6, 0.05, -0.01, 0.9],
      },
    },
  };
  const options = {
    triggerOnce: true,
    rootMargin: "-100px",
  };
  return (
    <section className="staggered-images">
      <div className="artwork-container left">
        {images.map(
          (image, index) =>
            index % 2 === 0 && (
              <AnimateWhenVisible
                key={`left-${index}`}
                variants={variants}
                options={options}
              >
                <img src={image} alt="artwork" loading="lazy" />
              </AnimateWhenVisible>
            )
        )}
      </div>
      <div className="artwork-container right">
        {images.map(
          (image, index) =>
            index % 2 !== 0 && (
              <AnimateWhenVisible
                key={`right-${index}`}
                variants={variants}
                options={options}
              >
                <img src={image} alt="artwork" />
              </AnimateWhenVisible>
            )
        )}
      </div>
    </section>
  );
};

export default StaggeredImages;
