import React, { useState } from "react";
import { useGlobalStateContext } from "../context/globalContext";
import useEventListener from "../hooks/useEventListener";

export default function Cursor({ mouse }) {
  const [opacity, setOpacity] = useState(false);

  const handleShowCursor = () => {
    setOpacity(true);
  };
  const handleHideCursor = () => {
    setOpacity(false);
  };

  useEventListener("mouseenter", handleShowCursor, document.body);
  useEventListener("mouseleave", handleHideCursor, document.body);

  const { cursorType } = useGlobalStateContext();

  return (
    <div className="cursors">
      <div
        className={`cursor ${cursorType}`}
        style={{
          top: mouse.mouse.y,
          left: mouse.mouse.x,
          opacity: opacity ? 1 : 0,
        }}
      />
      <div
        className={`cursor ${cursorType}`}
        style={{
          top: mouse.animated.y,
          left: mouse.animated.x,
          opacity: opacity ? 1 : 0,
        }}
      />
    </div>
  );
}
