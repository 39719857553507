import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../assets/vectors/logo.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useGlobalStateContext } from "../../context/globalContext";

export default function IntroOverlay() {
  // Get logo svg dimension and position for animation
  const [logoDimensions, setLogoDimensions] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  });

  useEffect(() => {
    setLogoDimensions(
      document.querySelector(".overlayLogo").getBoundingClientRect()
    );
  }, []);

  // Remove overlay to trigger exit animation
  const [exit, setExit] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setExit(false);
    }, 200);
  }, []);
  const { loading } = useGlobalStateContext();
  const addedDelay = 0.4;

  return (
    <AnimatePresence exitBeforeEnter>
      {(loading || exit) && (
        <div className="fullscreen">
          <motion.div
            className="side-strip"
            style={{
              height: logoDimensions.y,
            }}
            exit={{
              width: 0,
              transition: { duration: 0.4, delay: addedDelay },
            }}
          ></motion.div>
          <motion.div
            className="side-strip"
            style={{
              width: logoDimensions.x,
            }}
            exit={{
              height: 0,
              transition: { duration: 0.4, delay: addedDelay },
            }}
          ></motion.div>
          <motion.div
            className="side-strip"
            style={{
              height: logoDimensions.y,
              width: window.innerWidth - logoDimensions.x,
            }}
            exit={{
              width: 0,
              transition: { duration: 0.4, delay: 0.4 + addedDelay },
            }}
          ></motion.div>
          <motion.div
            className="side-strip"
            style={{
              width: logoDimensions.x,
              height: window.innerHeight - logoDimensions.y,
            }}
            exit={{
              height: 0,
              transition: { duration: 0.4, delay: 0.4 + addedDelay },
            }}
          ></motion.div>
          <motion.div
            className="inner-strip inner-strip-1"
            style={{
              bottom: logoDimensions.y + logoDimensions.width * 0.2,
              left: logoDimensions.x + logoDimensions.width * 0.2,
              height: logoDimensions.height * 0.8,
              width: logoDimensions.width * 0.2,
            }}
            exit={{
              height: 0,
              transition: { duration: 0.4, delay: 0.4 + addedDelay },
            }}
          ></motion.div>
          <motion.div
            className="inner-strip inner-strip-2"
            style={{
              bottom: logoDimensions.y + logoDimensions.height * 0.2,
              left: logoDimensions.x + logoDimensions.width * 0.2,
              height: logoDimensions.height * 0.2,
              width: logoDimensions.width * 0.8,
            }}
            exit={{
              width: 0,
              transition: { duration: 0.4, delay: 0.4 + addedDelay },
            }}
          ></motion.div>
          <motion.div
            className="inner-square"
            style={{
              bottom: logoDimensions.y + logoDimensions.height * 0.4,
              left: logoDimensions.x + logoDimensions.width * 0.4,
              height: logoDimensions.height * 0.4,
              width: logoDimensions.width * 0.4,
            }}
            exit={{
              width: 0,
              height: 0,
              transition: { duration: 0.2, delay: 0.4 + addedDelay },
            }}
          ></motion.div>

          <motion.div
            className="overlay-logo-container"
            exit={{
              height: "500%",
              transition: { duration: 0.3, delay: 1 + addedDelay },
            }}
          >
            <Logo className="overlayLogo" />
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
