import React from "react";
import { motion } from "framer-motion";
import AnimateWhenVisible from "../AnimateWhenVisible";

export default function Steps({ steps, width }) {
  const variants = {
    initial: { opacity: 0, x: width > 800 ? 1000 : 200 },
    animate: { opacity: 1, x: 0 },
  };
  return (
    <section className="info-step">
      <div className="container">
        {steps.steps.map((step, index) => (
          <AnimateWhenVisible key={index} variants={variants}>
            <Step step={step} index={index} />
          </AnimateWhenVisible>
        ))}
      </div>
    </section>
  );
}

function Step({ step, index }) {
  return (
    <motion.div className="step">
      <h2>0{index + 1}</h2>
      <h2>{step.title}</h2>
      <p>{step.copy}</p>
    </motion.div>
  );
}
