import React, { useState } from "react";

export default function FAQ({ information, onCursor }) {
  const [questionActive, setQuestionActive] = useState([
    { active: false },
    { active: false },
    { active: false },
    { active: false },
  ]);
  const handleQuestion = (ii) => {
    let qs = questionActive.map((q, index) => {
      if (ii === index) {
        q.active = !q.active;
      } else {
        q.active = false;
      }
      return q;
    });
    setQuestionActive(qs);
  };
  return (
    <section className="info-section">
      <div className="container">
        <div className="questions">
          {information.questions.map((question, index) => {
            return (
              <div key={index}>
                <div
                  className="question"
                  onClick={() => handleQuestion(index)}
                  onMouseEnter={() => onCursor("hovered")}
                  onMouseLeave={onCursor}
                >
                  <h2>{question.title}</h2>
                  <svg
                    style={{
                      transform: questionActive[index].active
                        ? "rotate(270deg)"
                        : "rotate(90deg)",
                    }}
                    viewBox="0 0 17 12"
                  >
                    <path d="M1,6h14"></path>
                    <path d="M12,1l4,5"></path>
                    <path d="M12,11l4-5"></path>
                  </svg>
                </div>
                <div
                  className="answer"
                  style={{
                    maxHeight: questionActive[index].active
                      ? "400px"
                      : "0.01px",
                  }}
                >
                  {question.answers.map((answer, index) => (
                    <p key={index}>{answer}</p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
