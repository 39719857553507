import React, { useEffect, useRef } from "react";
import { useGlobalStateContext } from "../context/globalContext";
import { useToggleLoading } from "../hooks/useContextSetters";

// Check if images have loaded
function imgLoaded(imgElement) {
  return imgElement.complete && imgElement.naturalHeight !== 0;
}

const LoadImages = ({ projects }) => {
  // Get all images and put in useRef array
  const images = useRef([]);
  const { loading } = useGlobalStateContext();
  const toggleLoading = useToggleLoading();
  useEffect(() => {
    // Default: set loading false even if images havent loaded in
    setTimeout(() => {
      toggleLoading();
    }, 3000);
    // Store truth values from image loaded check
    const imageLoadedValues = [];
    // Loop through images checking if they have loaded on an interval
    const checkImageCondition = setInterval(() => {
      for (let i = 0; i < images.current.length; i++) {
        const imageLoaded = imgLoaded(images.current[i]);
        if (imageLoaded && imageLoadedValues.length <= projects.length) {
          imageLoadedValues.push(imageLoaded);
        } else {
          imageLoadedValues.length = 0;
        }
      }
      if (imageLoadedValues.length === projects.length) {
        // Clear interval and loading variable once all images have returned true
        clearInterval(checkImageCondition);
        setTimeout(() => {
          toggleLoading();
        }, 1000);
      }
    }, 100);

    return () => {
      clearInterval(checkImageCondition);
    };

    // eslint-disable-next-line
  }, []);
  return (
    <>
      {loading && (
        <div
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            overflow: "hidden",
          }}
        >
          {projects.map((project, index) => (
            <img
              key={index}
              ref={(el) => {
                if (images.current.length < projects.length) {
                  images.current.push(el);
                }
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
              src={
                require(`../assets/images/${project.name}/card/card3200.jpg`)
                  .default
              }
              alt="no"
            />
          ))}
        </div>
      )}
    </>
  );
};

export default LoadImages;
