import React, { useRef, useState } from "react";
import useEventListener from "../../hooks/useEventListener";

export default function HorizontalTextScroll({ information, dimensions }) {
  const offset = 800;
  const maxOffset = 3000;
  const delay = 275;
  const denominator = 350;
  const serve = useRef();
  const [scroll, setScroll] = useState(0);
  const [transitioning, setTransitioning] = useState(false);

  const handleScroll = () => {
    setTransitioning(true);
    setScroll(document.documentElement.scrollTop);
  };
  const handleTransitionEnd = () => {
    setTransitioning(false);
  };
  useEventListener("wheel", handleScroll, document.body);
  useEventListener("transitionend", handleTransitionEnd, serve.current);

  const handleMap = (val, src = [], dst = []) => {
    return ((val - src[0]) / (src[1] - src[0])) * (dst[1] - dst[0]) + dst[0];
  };
  return (
    <section className="info-section">
      <div className="services">
        {information.services.map((service, index) => {
          return (
            <div
              ref={serve}
              className={`service ${transitioning && "transitioning"}`}
              key={index}
              style={{
                transform: `translate3d(${
                  dimensions.width > 800
                    ? index % 2 === 0
                      ? scroll >= offset - 1500 + index * delay &&
                        scroll - offset - index * delay
                      : scroll >= offset - 1500 + index * delay &&
                        offset + index * delay - scroll
                    : 0
                }px, 0, 0)`,
                opacity:
                  dimensions.width > 800
                    ? scroll > offset + index * delay &&
                      scroll < maxOffset + index * delay &&
                      handleMap(
                        ((scroll - index * delay) * (scroll - index * delay)) /
                          denominator,
                        [offset + index * delay, maxOffset + index * 200],
                        [1, 0]
                      )
                    : 1,
              }}
            >
              <h2>{service}</h2>
            </div>
          );
        })}
      </div>
    </section>
  );
}
