import React from "react";
import { useGlobalStateContext } from "../../context/globalContext";
import AnimateWhenVisible from "../AnimateWhenVisible";

export default function Design({ name, branding: { text, images } }) {
  const {
    data: { animation },
  } = useGlobalStateContext();
  return (
    <section className="branding">
      <section className="project-copy">
        <div className="container">
          <div className="text-container">
            <h2>{text.heading}</h2>
            <p>{text.copy}</p>
          </div>
        </div>
      </section>
      <section className="project-images">
        <div className="container">
          <div className="squares-wrapper">
            <AnimateWhenVisible
              variants={animation.variants}
              options={animation.options}
            >
              <div className="row">
                {images.squares.map((square, index) => {
                  return (
                    <picture key={index}>
                      {/* <source srcSet="img.webp" type="image/webp"/> */}
                      <source
                        srcSet={
                          require(`../../assets/images/${name}/${
                            index === 0 ? "squares-front" : "squares-back"
                          }/squares1300.jpg`).default
                        }
                        media="(min-width: 650px)"
                      />
                      <source
                        srcSet={
                          require(`../../assets/images/${name}/${
                            index === 0 ? "squares-front" : "squares-back"
                          }/squares800.jpg`).default
                        }
                        media="(min-width: 300px)"
                      />
                      <source
                        srcSet={
                          require(`../../assets/images/${name}/${
                            index === 0 ? "squares-front" : "squares-back"
                          }/squares500.jpg`).default
                        }
                        media="(min-width: 150px)"
                      />
                      <img
                        key={index}
                        src={
                          require(`../../assets/images/${name}/${
                            index === 0 ? "squares-front" : "squares-back"
                          }/squares300.jpg`).default
                        }
                        alt={square.alt}
                        loading="lazy"
                      ></img>
                    </picture>
                  );
                })}
              </div>
            </AnimateWhenVisible>
          </div>
          <AnimateWhenVisible
            variants={animation.variants}
            options={animation.options}
          >
            {images.mockups.map((mockup, index) => {
              return (
                <picture key={index}>
                  <img
                    key={index}
                    src={
                      require(`../../assets/images/${mockup.image}.jpg`).default
                    }
                    alt={mockup.alt}
                    loading="lazy"
                  ></img>
                </picture>
              );
            })}
          </AnimateWhenVisible>
        </div>
      </section>
    </section>
  );
}
