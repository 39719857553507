import React from "react";
import AnimateWhenVisible from "../AnimateWhenVisible";

export default function Creativity({ information }) {
  const variants = {
    initial: {
      y: 0,
      opacity: 0,
      scale: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      scale: 1,
    },
  };
  const options = {
    threshhold: 0,
    triggerOnce: true,
  };
  return (
    <section className="info-section">
      <div className="container">
        <div className="text-container">
          <h2>{information.title}</h2>
          <p>{information.copy}</p>
        </div>
        <div className="parallax-images">
          <div className="artwork-container left">
            {information.images.map(
              (image, index) =>
                index % 2 === 0 && (
                  <AnimateWhenVisible
                    key={`left-${index}`}
                    variants={variants}
                    options={options}
                  >
                    <img src={image} alt="artwork" />
                  </AnimateWhenVisible>
                )
            )}
          </div>
          <div className="artwork-container right">
            {information.images.map(
              (image, index) =>
                index % 2 !== 0 && (
                  <AnimateWhenVisible
                    key={`right-${index}`}
                    variants={variants}
                    options={options}
                  >
                    <img src={image} alt="artwork" />
                  </AnimateWhenVisible>
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
