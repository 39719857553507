import React, { useEffect } from "react";
import { data } from "../data/data";
import { useAnimation, motion } from "framer-motion";

export const LoadingAnimation = () => {
  const icons = data.icons;
  const duration = 0.2;
  const controls = useAnimation();

  useEffect(() => {
    controls.start("hidden");
  }, [controls]);
  return (
    <>
      {icons.map((icon, index) => {
        return (
          <motion.div
            key={index}
            className="icon-wrapper"
            animate={controls}
            variants={{
              hidden: {
                opacity: [0, 1, 0],
                transition: {
                  duration: duration,
                  repeatDelay: duration * (icons.length - 1),
                  delay: duration * index,
                  repeat: Infinity,
                  repeatType: "loop",
                },
              },
            }}
          >
            {icon}
          </motion.div>
        );
      })}
    </>
  );
};

export default function Loader() {
  return (
    <motion.div className="loader" onClick={(e) => e.stopPropagation()}>
      <LoadingAnimation />
    </motion.div>
  );
}
