import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

export default function AnimatedLetters({
  sentance = "Pass in a sentance",
  concurrent = false,
  height = 400,
  stagger = 0.1,
  duration = 0.8,
  center = false,
  fontSize = "clamp(2.5rem, 4vw, 5.5rem)",
  fontWeight = "700",
}) {
  // STYLES
  const sentanceStyle = {
    position: "relative",
    display: "flex",
    justifyContent: center && "center",
    alignItems: center && "center",
    flexWrap: "wrap",
    overflow: "hidden",
  };
  const wordStyle = {
    position: "relative",
    display: "flex",
    fontFamily: "'Lato', sans-serif",
    fontSize: fontSize,
    fontWeight: fontWeight,
  };

  // VARIANTS
  const letterAnimation = {
    initial: { y: height },
    animate: (i) => ({
      y: 0,
      transition: {
        duration: duration,
        ease: [0.6, 0.01, -0.05, 0.95],
        delay: i * stagger + 0.2,
      },
    }),
  };

  // FUNCTIONS
  const splitIntoWords = (string) => {
    const words = string.split(" ");
    const arrayOfWords = [];
    words.map((word) => {
      const w = [...word];
      return arrayOfWords.push(w);
    });
    return arrayOfWords;
  };
  const createSpans = (string) => {
    const words = splitIntoWords(string);
    let count = 0;
    return words.map((word, i) => {
      const letters = word.map((letter, ii) => {
        count++;
        return (
          <motion.span
            key={`${letter}-${ii}`}
            className="letter"
            style={{ textTransform: i === 0 && ii === 0 && "uppercase" }}
            custom={concurrent ? ii : count}
            variants={letterAnimation}
            initial="initial"
            animate="animate"
          >
            {ii === word.length - 1 ? `${letter} \u00A0` : letter}
          </motion.span>
        );
      });

      return (
        <motion.span key={`${word}-${i}`} className="word" style={wordStyle}>
          {letters}
        </motion.span>
      );
    });
  };

  const [data, setData] = useState(null);
  const ref = useRef(createSpans(sentance));
  useEffect(() => {
    setData(ref.current);
  }, []);

  return (
    <>
      {data && (
        <motion.span className="sentance" style={sentanceStyle}>
          {data}
        </motion.span>
      )}
    </>
  );
}
