import React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AnimatedLetters2 from "../AnimatedLetters2";
import useRouter from "../../hooks/useRouter";
import { ReactComponent as Arrow } from "../../assets/vectors/arrow.svg";
import useEventListener from "../../hooks/useEventListener";
import { useUpdateCursor } from "../../hooks/useContextSetters";
import { useGlobalStateContext } from "../../context/globalContext";

const wrap = (min, max, value) => {
  const range = max - min;
  return (Math.abs(value) % range) + min;
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

export const CardV2 = ({ projects, dimensions, scale, parallax }) => {
  const { push } = useRouter();
  const onCursor = useUpdateCursor();
  const [[page, direction], setPage] = useState([0, 0]);

  const variants = {
    enter: (direction) => {
      return {
        // x: direction > 0 ? 1000 : -1000,
        // scale: direction < 0 ? 0.7 : 1.3,
        opacity: 0,
      };
    },
    center: {
      // zIndex: 1,
      // x: 0,
      scale: 1,
      opacity: 1,
      transition: { duration: 0.5 },
    },
    exit: (direction) => {
      return {
        // zIndex: 0,
        // scale: direction < 0 ? 0.7 : 1.3,
        // x: direction < 0 ? 1000 : -1000,
        opacity: 0,
        transition: { duration: 0.5 },
      };
    },
    transitionInitial: {
      width: "40%",
      x: dimensions.width > 800 && parallax.value.x * -0.05,
      y: dimensions.width > 800 && parallax.value.y * -0.05,
    },
    transition: {
      height: "100vh",
      width: "100vw",
      scale: scale,
      x: 0,
      y: 0,
      transition: { duration: 0.6, ease: [0.6, 0.01, -0.05, 0.95] },
    },
  };

  const imageIndex = wrap(0, projects.length, page);

  const [toggle, setToggle] = useState(true);
  const [click, setClick] = useState(false);
  const paginate = (newDirection) => {
    setPage([page + newDirection, newDirection]);
    setToggle(false);
    setTimeout(() => {
      setToggle(true);
    }, 100);
  };

  const delayRedirect = (e, to) => {
    e.preventDefault();
    setClick(true);
    // if (!allowRedirect.current) return;
    // if (!redirect) return;
    setTimeout(() => push(to), 1000);
    // setRedirect(false);
    // setTimeout(function () {
    //   setRedirect(true);
    // }, 5000);
  };

  const [scrollAvailable, setScrollAvailable] = useState(true);
  const { loading } = useGlobalStateContext();
  const handleScroll = (e) => {
    if (loading) return;
    const dY = e.deltaY / Math.abs(e.deltaY);
    // shifting.current = true;
    if (dimensions.width > 800 && scrollAvailable) {
      paginate(-dY);
      setScrollAvailable(false);
      setTimeout(() => {
        setScrollAvailable(true);
      }, 250);
    }
  };

  useEventListener("wheel", handleScroll, document.body);

  return (
    <>
      {click && (
        <motion.img
          key={page}
          src={
            require(`../../assets/images/${projects[imageIndex].name}/card/card3200.jpg`)
              .default
          }
          custom={direction}
          variants={variants}
          initial="transitionInitial"
          animate="transition"
        />
      )}
      {!click && (
        <AnimatePresence initial={false} custom={direction}>
          <motion.img
            onClick={(e) =>
              delayRedirect(e, `projects/${projects[imageIndex].name}`)
            }
            onMouseEnter={() => onCursor("hovered")}
            onMouseLeave={onCursor}
            key={page}
            src={
              require(`../../assets/images/${projects[imageIndex].name}/card/card3200.jpg`)
                .default
            }
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            style={{
              x: dimensions.width > 800 && parallax.value.x * -0.05,
              y: dimensions.width > 800 && parallax.value.y * -0.05,
            }}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          />
          {toggle && (
            <AnimatedLetters2
              key={"backgroundLetters"}
              sentance={projects[imageIndex].name}
              position="absolute"
              fontSize="clamp(1rem, 15vw, 25rem)"
              fontWeight="900"
              letterSpacing="0 clamp(0.1rem, 1vw, 1.5rem)"
              center
              height={1000}
              color="rgb(10, 30, 65)"
              stagger={0.1}
              duration={0.7}
              lineHeight={5}
              zIndex={dimensions.width < 800 ? 3 : 1}
            />
          )}
          {toggle && dimensions.width > 800 && (
            <AnimatedLetters2
              key={"strokeLetters"}
              sentance={projects[imageIndex].name}
              zIndex={3}
              position="absolute"
              fontSize="clamp(1rem, 15vw, 25rem)"
              fontWeight="900"
              letterSpacing="0 clamp(0.1rem, 1vw, 1.5rem)"
              center
              height={1000}
              color="rgba(10, 30, 65, 0.15)"
              stagger={0.1}
              duration={0.7}
              lineHeight={5}
              stroke
            />
          )}
        </AnimatePresence>
      )}
      <AnimatePresence>
        {!click && (
          <motion.div exit={{ opacity: 0 }} className="controls">
            <div
              className="prev"
              onClick={() => paginate(-1)}
              onMouseEnter={() => onCursor("hovered")}
              onMouseLeave={onCursor}
            >
              <Arrow />
            </div>
            <div className="progress-wrapper">
              {projects.map((project, index) => (
                <div
                  className={`dot ${imageIndex === index && "active"}`}
                  key={index}
                  onClick={() => paginate(index - imageIndex)}
                  onMouseEnter={() => onCursor("pointer")}
                  onMouseLeave={onCursor}
                ></div>
              ))}
              {/* // ! number */}
              {/* <h2 className="number current">{imageIndex + 1}</h2>
              <h2 className="number">/</h2>
            <h2 className="number total">{projects.length}</h2> */}
            </div>
            <div
              className="next"
              onClick={() => paginate(1)}
              onMouseEnter={() => onCursor("hovered")}
              onMouseLeave={onCursor}
            >
              <Arrow />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
