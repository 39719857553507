import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import useRouter from "../hooks/useRouter";
import { ReactComponent as Logo } from "../assets/vectors/logo.svg";
import { AnimatePresence, motion } from "framer-motion";
import { useUpdateCursor } from "../hooks/useContextSetters";

export default function Header() {
  const { push, pathname } = useRouter();
  const onCursor = useUpdateCursor();
  const [menuState, setMenuState] = useState({
    menuOpened: false,
  });
  const [redirect, setRedirect] = useState(true);
  const blend = useRef(true);
  const vis = useRef(false);

  const disableScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    window.onscroll = () => {
      window.scrollTo(scrollLeft, scrollTop);
    };
  };
  const enableScroll = () => {
    window.onscroll = () => {};
  };

  const delayRedirect = (e, to) => {
    e.preventDefault();
    if (!redirect) return;
    setTimeout(() => push(to), 0);
    setRedirect(false);
    setTimeout(() => {
      setRedirect(true);
    }, 1000);
  };
  const handleScroll = useCallback(() => {
    if (menuState.menuOpened) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [menuState.menuOpened]);

  useEffect(() => {
    if (menuState.menuOpened) {
      blend.current = false;
    } else {
      setTimeout(() => {
        blend.current = true;
      }, 500);
      setTimeout(() => {
        vis.current = false;
      }, 800);
    }
  }, [menuState]);

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);

  return (
    <>
      <div className={`header ${blend.current && "mix-blend"}`}>
        <div className="container">
          <div className="row v-center space-between">
            <button
              className="logo"
              onMouseEnter={() => onCursor("hovered")}
              onMouseLeave={onCursor}
            >
              <Link
                to="/"
                onClick={(e) => {
                  vis.current = true;
                  setMenuState({ menuOpened: false });
                  delayRedirect(e, "/");
                }}
              >
                <Logo />
              </Link>
            </button>
            <div className="nav-toggle">
              <button
                className="hamburger-menu"
                onClick={() => {
                  setMenuState({ menuOpened: !menuState.menuOpened });
                }}
                onMouseEnter={() => onCursor("hovered")}
                onMouseLeave={onCursor}
              >
                <AnimatePresence>
                  {!menuState.menuOpened && (
                    <motion.span
                      key={"span-1"}
                      initial={{ y: 10 }}
                      animate={{ y: 0 }}
                      exit={{ y: 10 }}
                    ></motion.span>
                  )}
                  <motion.span initial={false} animate={{ y: 0 }}></motion.span>
                  {!menuState.menuOpened && (
                    <motion.span
                      key={"span-2"}
                      initial={{ y: -10 }}
                      animate={{ y: 0 }}
                      exit={{ y: -10 }}
                    ></motion.span>
                  )}
                </AnimatePresence>
              </button>
            </div>
          </div>
          <AnimatePresence>
            {menuState.menuOpened && (
              <motion.nav
                initial={{ height: 0 }}
                animate={{
                  height: "100%",
                }}
                exit={{ height: 0, transition: { duration: 0.3 } }}
              >
                <div className="container">
                  <ul className="nav-links">
                    <li>
                      <Link
                        to="/"
                        onClick={(e) => {
                          vis.current = true;
                          setMenuState({ menuOpened: !menuState.menuOpened });
                          delayRedirect(e, "/");
                        }}
                      >
                        Projects
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/about"
                        onClick={(e) => {
                          pathname !== "/about" && (vis.current = true);
                          setMenuState({ menuOpened: !menuState.menuOpened });
                          delayRedirect(e, "/about");
                        }}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/approach"
                        onClick={(e) => {
                          pathname !== "/approach" && (vis.current = true);
                          setMenuState({ menuOpened: !menuState.menuOpened });
                          delayRedirect(e, "/approach");
                        }}
                      >
                        Approach
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/services"
                        onClick={(e) => {
                          pathname !== "/services" && (vis.current = true);
                          setMenuState({ menuOpened: !menuState.menuOpened });
                          delayRedirect(e, "/services");
                        }}
                      >
                        Services
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/contact"
                        onClick={(e) => {
                          pathname !== "/contact" && (vis.current = true);
                          setMenuState({ menuOpened: !menuState.menuOpened });
                          delayRedirect(e, "/contact");
                        }}
                      >
                        Contact
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </motion.nav>
            )}
          </AnimatePresence>
        </div>
      </div>
      <AnimatePresence exitBeforeEnter>
        {vis.current && (
          <motion.div
            className="header-transition-overlay"
            initial={{ height: 0 }}
            animate={{
              height: "100%",
              transition: {
                duration: 0.3,
                ease: [0.6, 0.01, -0.05, 0.95],
              },
            }}
            exit={{
              height: 0,
              transition: {
                duration: 0,
                delay: 0.5,
              },
            }}
          ></motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
