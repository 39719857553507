import React from "react";
import { ReactComponent as LocationSVG } from "../../assets/vectors/locations.svg";

export default function Location({ information }) {
  return (
    <section className="info-section">
      <div className="container">
        <div className="text-container">
          <h2>{information.title}</h2>
          <p>{information.copy}</p>
        </div>
        <LocationSVG />
      </div>
    </section>
  );
}
